import React from 'react'

export const Footer = () => {
  return (
    <footer className="footer mt-auto w-full h-[100px] bg-[#006BA6]">
        <h1 className='text-2xl text-center pt-5 font-Anton text-white'>JCHernandez SIP</h1>
        <p className='justify-end text-center text-white '>Todos los derechos reservados © 2024</p>
    </footer>
  )
}
