import img1 from "../assets/img/canierias.jpeg";
import img2 from "../assets/img/techos_paneles_sip.jpg";
import img3 from "../assets/img/sipcorConst.jpeg";
import img4 from "../assets/img/ventanal.jpeg";
import img5 from "../assets/img/quinchoSipco.jpeg";
import img6 from "../assets/img/sipcorCasa.jpeg";
import img7 from "../assets/img/poruferasip.jpeg";


export const data = [
    
    {
        
        img: img2,
    },
    {
        
        img: img3,
    },
    {
        
        img: img1,
    },
    {
        
        img: img4,
    },
    {
        
        img: img5,
    },
    {
        img: img6,
    },
    {
        img: img7,
    }

];